/* =======================
   Articles
   ======================= */

.articles {
	background: #f8fbfc url(../i/articles.png) repeat 50% 50%;
}

.articles__inner {
	@include last-margin;
	@include media($xl) {
		padding: 45px 30px;
	}
	@include media($lg) {
		padding: 45px 30px;
	}
	@include media($md) {
		padding: 40px 15px;
	}
	@include media($sm) {
		padding: 30px 15px;
	}

	max-width: 1230px;
	margin: 0 auto;

	text-align: center;
}

.articles__title {
	@extend .about__title;
}

.articles__list {
	@include media($xl) {
		margin-bottom: 45px;
	}
	@include media($lg) {
		margin-bottom: 45px;
	}
	@include media($md) {
		margin-bottom: 40px;
	}
	@include media($sm) {
		@include last-margin;

		display: block;

		margin: 0 -15px 30px;
	}

	display: flex;

	margin-right: -1px;
	margin-left: -1px;
}

.articles__item {
	@include media($sm) {
		width: 100%;
		max-width: 390px;
		margin: 0 auto 2px;
	}

	position: relative;

	display: block;

	width: calc(33.3333% - 2px);
	margin: 0 1px;

	color: white;
	&:hover {
		text-decoration: none;
	}
}

.articles__hero {
	display: block;

	max-width: 100%;
	height: auto;
}

.articles__caption {
	@include media($xl) {
		font-size: 17px;
		line-height: 20px;

		min-height: 70px;
		padding: 15px 30px;
	}
	@include media($lg) {
		font-size: 17px;
		line-height: 20px;

		min-height: 60px;
		padding: 10px 30px;
	}
	@include media($md) {
		font-size: 15px;
		line-height: 20px;

		min-height: 50px;
		padding: 5px 15px;
	}
	@include media($sm) {
		font-size: 15px;
		line-height: 20px;

		min-height: 60px;
		padding: 10px 15px;

		text-align: center;
	}

	font-size: 17px;
	font-weight: bold;
	line-height: 20px;

	position: absolute;
	bottom: 0;
	left: 0;

	display: flex;

	width: 100%;

	text-align: left;

	background: rgba(28,38,47,.65);

	align-items: center;
	.articles__item:hover & {
		background: $blue;
	}
}
