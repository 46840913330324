.banner {
	@include media($md) {
		display: none;
	}
	@include media($sm) {
		display: none;
	}

	margin-top: 30px;
	img {
		display: block;

		max-width: 100%;
		height: auto;
		margin-right: auto;
		margin-left: auto;
	}
}
