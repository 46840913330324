/* =======================
   Location
   ======================= */

.location {
	@include last-margin;

	max-width: 940px;
	margin-bottom: 30px;
	input[type='checkbox'] {
		margin-right: .25em;
	}
}

.location__row {
	@include media($sm) {
		display: block;

		margin-bottom: 1em;
	}

	display: flex;

	margin: 0 -15px 30px;

	flex-wrap: wrap;
}

.location__item {
	@include media($sm) {
		width: auto;
		margin-bottom: 1em;
	}

	width: 33.3333%;
	padding: 0 15px;
	.checkbox {
		@include media($sm) {
			padding-top: 0;
		}

		padding-top: 7px;
	}
}

.location__label {
	font-weight: bold;

	margin-bottom: .5em;
}

.location__map {
    height: 439px;
    margin: 30px 0;
}

.location-wrapper {
	position: relative;
	min-height: 595px;

	&.init {
		min-height: 1px;

		&::before {
			display: none;
		}
	}

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 55px;
		height: 55px;
		background: url(../i/preloader-blue-1.gif) no-repeat center center;
		background-size: contain;
	}
}
