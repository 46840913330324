.promo {
	position: relative;
}

.promo__item {
	height: auto;

	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}

.promo__body {
	@include media($xl) {
		min-height: 590px;
		padding: 0 90px;
	}
	@include media($lg) {
		min-height: 590px;
		padding: 0 90px;
	}
	@include media($md) {
		min-height: 370px;
		padding: 0 30px;
	}
	@include media($sm) {
		flex-direction: column;

		padding: 20px 15px 60px;
	}

	display: flex;

	max-width: 1230px;
	margin: 0 auto;
}

.promo__hero {
	@include media($xl) {
		width: 400px;
		padding: 0 30px;
	}
	@include media($lg) {
		width: 380px;
		padding: 0 30px;
	}
	@include media($md) {
		width: 280px;
		padding: 35px 30px;
	}
	@include media($sm) {
		order: 1;
	}

	display: flex;

	align-items: center;
	justify-content: center;
}

.promo__pic {
	@include media($md) {
		max-height: 300px;
	}
	@include media($sm) {
		max-height: 210px;
	}

	display: block;

	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

.promo__info {
	@include media($xl) {
		width: calc(100% - 400px);
		padding: 115px 0;
	}
	@include media($lg) {
		width: calc(100% - 380px);
		padding: 115px 0;
	}
	@include media($md) {
		width: calc(100% - 280px);
		padding: 35px 0 60px;
	}
	@include media($sm) {
		order: 0;
	}

	font-weight: bold;

	color: $black;
}

.promo__caption {
	@include media($xl) {
		font-size: 34px;
		line-height: 1;
	}
	@include media($lg) {
		font-size: 34px;
		line-height: 1;
	}
	@include media($md) {
		font-size: 30px;
		line-height: 32px;
	}
	@include media($sm) {
		font-size: 24px;
		line-height: 1.25;

		text-align: center;
	}

	font-weight: bold;

	margin-bottom: .75em;
}


.promo__description {
	@include media($xl) {
		font-size: 22px;
		line-height: 1;
	}
	@include media($lg) {
		font-size: 22px;
		line-height: 1;
	}
	@include media($md) {
		font-size: 20px;
	}
	@include media($sm) {
		font-size: 17px;
		line-height: 1.25;
	}
}

.promo__description,
.promo__footer {
	@include media($sm) {
		display: none;
	}
}

.promo__price {
	@include media($xl) {
		font-size: 30px;
	}
	@include media($lg) {
		font-size: 30px;
	}
	@include media($md) {
		font-size: 24px;
	}
	@include media($sm) {
		display: none;
	}

	font-weight: bold;

	margin-bottom: 1em;
}

.promo__pagination {
	@include media($xl) {
		bottom: 70px;
	}
	@include media($lg) {
		bottom: 70px;
	}
	@include media($md) {
		bottom: 28px;
	}
	@include media($sm) {
		bottom: 20px;
	}

	position: absolute;
	z-index: 1;
	right: 0;
	left: 0;

	display: flex;

	align-items: center;
	justify-content: center;
}

.promo__bullet {
	width: 10px;
	height: 10px;
	margin: 4px 5px;

	cursor: pointer;
	transition: all .2s ease;

	border: 4px solid white;
	border-radius: 50%;
	background: white;
	&:hover {
		border-color: $blue;
		background: $blue;
	}
	&_active {
		width: 18px;
		height: 18px;
		margin: 0 5px;

		border-color: $blue;
		background: transparent !important;
	}
}

.promo__prev,
.promo__next {
	@include media($md) {
		display: none;
	}
	@include media($sm) {
		display: none;
	}

	font-size: 48px;
	line-height: 1;

	position: absolute;
	z-index: 1;
	top: 50%;

	width: 48px;
	height: 48px;

	cursor: pointer;
	transform: translateY(-50%);

	color: $black;
	&:before {
		font-family: iconfont;
	}
	&:hover {
		color: $blue;
	}
}

.promo__prev {
	@include media($xl) {
		left: 50%;

		margin-left: -570px;
	}
	@include media($lg) {
		left: 45px;
	}
	&:before {
		content: '\e904';
	}
}

.promo__next {
	@include media($xl) {
		right: 50%;

		margin-right: -570px;
	}
	@include media($lg) {
		right: 45px;
	}
	&:before {
		content: '\e905';
	}
}
