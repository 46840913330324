.rubrics {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
}

.rubrics__item {

	@include media($sm) {
		width: calc(50% - 30px);
	}

	@include media($xs) {
		width: 100%;
	}

	width: calc(33.3333% - 30px);
	display: block;
	margin: 0 15px 30px;
	font-size: 16px;
	line-height: 1.25;
	color: $grey;
	background: #f8fbfc;

	&:hover {
		text-decoration: none;
		color: white;
		background: $blue;
	}
}

.rubrics__hero {
	display: block;
	width: 100%;
	max-width: 100%;
}

.rubrics__info {
	@include last-margin;

	position: relative;
	padding: 20px;
	text-align: center;

	&:before {
		@include triangle(18px 8px, #f8fbfc, up);

		position: absolute;
		bottom: 100%;
		left: 50%;
		display: block;
		content: '';
		transform: translateX(-50%);

		.rubrics__item:hover & {
			border-bottom-color: $blue;
		}
	}
}

.rubrics__caption {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: .5em;
	color: $blue;

	.rubrics__item:hover & {
		text-decoration: underline;
		color: white;
	}
}
