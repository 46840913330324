/* =======================
   Tags
   ======================= */

.tags {
	position: absolute;
	z-index: 2;
	top: 30px;
	left: 30px;
	.images & {
		top: 15px;
		left: 15px;
	}
	.related & {
		top: 15px;
		left: 30px;
	}
}

.tags__item {
	font-size: 15px;
	font-weight: bold;
	line-height: 20px;

	float: left;
	clear: left;

	margin-bottom: 1px;
	padding: 5px 12px;

	color: white;

	&-hit {
		background: $blue;
	}

	&-special {
		background: #dec017;
	}

	&-novelty {
		background: $blue;
	}
}
