.products {
	
	@include media($xs) {
		display: block;
	}
	display: flex;
	margin: 0 -15px 30px;
	flex-wrap: wrap;
}

.products__item {
	
	@include media($sm) {
		width: 50%;
	}
	
	@include media($xs) {
		width: auto;
	}

	position: relative;
	width: 33.3333%;
	padding: 30px;
	
	&:before {
		position: absolute;
		top: 0;
		right: 15px;
		left: 15px;
		display: block;
		height: 1px;
		content: '';
		background: #d9d9d9;
	}
	
	&:after {
		
		@include media($xs) {
			display: none;
		}

		position: absolute;
		top: 15px;
		bottom: 15px;
		left: 0;
		display: block;
		width: 1px;
		content: '';
		background: #d9d9d9;
	}
	
	&:nth-child(-n+3):before {
		
		@include media($sm) {
			display: block;
		}

		display: none;
	}
	
	&:nth-child(3n+1):after {
		
		@include media($sm) {
			display: block;
		}

		display: none;
	}
	
	&:nth-child(-n+2):before {
		
		@include media($sm) {
			display: none;
		}
		
		@include media($xs) {
			display: block;
		}
	}
	
	&:nth-child(2n+1):after {
		
		@include media($sm) {
			display: none;
		}
	}
	
	&:first-child:before {
		
		@include media($xs) {
			display: none;
		}
	}
}

.products__description {
	margin-top: 5px;
	font-size: 15px;
	text-align: center;
}

.products__price {
	margin-top: 5px;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	color: $black;
}

.products__btn {
	margin-top: 10px;
	text-align: center;
}