/* =======================
   Retailers
   ======================= */

.rtls {
	margin-bottom: 30px;
}

.rtls__item {
	margin-bottom: 20px;
	padding: 0 0 20px;

	border-bottom: 1px solid #e8f0f7;
	&:last-child {
		margin-bottom: 0;
		padding-bottom: 0;

		border-bottom: 0;
	}
}

.rtls__header {
	margin-bottom: .5em;
}

.rtls__name {
	@include media($sm) {
		display: block;

		margin-right: 0;
	}

	font-weight: bold;

	display: inline-block;

	margin-right: 1em;
}

.rtls__maplink {
	border-bottom: 1px dashed;
    color: $blue;
    cursor: pointer;
    user-select: none;

	&:hover {
        border-bottom: 0;
		text-decoration: none;
	}
}

.rtls__adr {
	margin-bottom: .5em;
}
