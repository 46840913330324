@charset 'UTF-8';

$ptsans: 'PT Sans', sans-serif;

$grey: #595959;
$black: #272727;

$darkblue: rgb(115, 173, 216);
$blue: rgb(0, 93, 180);

$xl: min-width 1230px;
$lg: min-width 980px max-width 1229px;
$md: min-width 768px max-width 979px;
$sm: max-width 767px;
$xs: max-width 479px;