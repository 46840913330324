.product {
	@include media($sm) {
		display: block;
	}

	display: flex;
	margin-bottom: 30px;
}

.product__leftside {
	@include last-margin;
	@include media($sm) {
		width: auto;
		margin-bottom: 30px;
	}

	width: 41.2%;
}

.product__rightside {
	@include last-margin;
	@include media($sm) {
		width: auto;
		padding-left: 0;
	}

	width: 58.8%;
	padding-left: 30px;
}

.product__remark {
	margin-bottom: 20px;
	font-weight: bold;
	color: $black;

	& small {
		font-weight: normal;
	}
}

.product__info {
	display: inline-flex;
	flex-wrap: wrap;
	align-items: center;
	vertical-align: top;
	margin-bottom: 20px;
	padding: 15px;
	padding-bottom: 0;
	color: $black;
	background-color: #f8fbfc;
}

.product__size {
	margin-right: 40px;
	margin-bottom: 15px;
	font-size: 20px;
	font-weight: bold;

	@media only screen and (max-width: 1140px) {
		margin-right: 30px;
	}
}

.product__price {
	margin-right: 40px;
	margin-bottom: 15px;
	font-size: 26px;
	font-weight: bold;

	@media only screen and (max-width: 1140px) {
		margin-right: 30px;
	}
}

.product__btn {
	margin-bottom: 15px;
}