.pop-up {
	position: fixed;
	z-index: 1100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	transition: 0.2s;
	visibility: hidden;
	opacity: 0;

	&.active {
		visibility: visible;
		opacity: 1;

		&::after {
			visibility: visible;
			opacity: 0.7;
		}
	}

	&::before {
		content: "";
		position: absolute;
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);
		width: 120px;
		height: 120px;
		border-radius: 50%;
		background-color: #fff;
		animation-name: pulse;
		animation-duration: 1s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		visibility: hidden;
		opacity: 0;
		transition: 0.2s;
	}

	&::after {
		content: "";
		position: fixed;
		z-index: -1;
		top: -100%;
		right: -100%;
		bottom: -100%;
		left: -100%;
		background: #000;
		cursor: pointer;
		transition: 0.2s;
		visibility: hidden;
		opacity: 0;
	}

	&.loading {

		&::before {
			visibility: visible;
			opacity: 0.7;
		}
	}

	.mobile & {
		display: none;

		&::before {
			display: none;
		}

		&::after {
			display: none;
		}

		&.active {
			display: block;

			&::after {
				display: block;
			}
		}

		&.loading {

			&::before {
				display: block;
			}
		}
	}

	&__insides {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 100%;
		min-width: 280px;
		max-width: 700px;
		margin-top: 63px;
		margin-bottom: 20px;
		padding: 40px;
		text-align: left;
		background-color: #fff;

		@media only screen and (max-width: 500px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&__close {
		position: absolute;
		right: 0;
		bottom: calc(100% + 20px);
		width: 23px;
		height: 23px;
		font-size: 0;
		background: url("../i/close-white-1.svg") no-repeat center center;
	}

	&__primary {
		text-align: center;

		& > :last-child {
			margin-bottom: 0;
		}
	}

	&__title {
		margin-bottom: 17px;
		font-size: 24px;
		font-weight: bold;
	}

	&__description {
		font-size: 14px;

		& > :last-child {
			margin-bottom: 0;
		}

		& p {
			margin: 0;
			margin-bottom: 24px;
		}
	}
}

@keyframes pulse {

	0% {
		width: 120px;
		height: 120px;
		opacity: 0.7;
	}

	50% {
		width: 80px;
		height: 80px;
		opacity: 0.4;
	}

	100% {
		width: 120px;
		height: 120px;
		opacity: 0.7;
	}
}