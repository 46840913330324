.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1230px;
	margin-left: auto;
	margin-right: auto;
	padding: 15px 30px;

	@include media($sm) {
		padding-left: 15px;
		padding-right: 15px;
	}

	&__we {
		display: flex;
		align-items: center;
	}

	&__logo {
		display: block;
		width: 117px;
		min-width: 117px;
		height: 68px;
		margin-right: 25px;
		font-size: 0;
		background: url(../i/logo.png) no-repeat 50% 50%;
		background-size: contain;

		@media only screen and (max-width: 560px) {
			width: 84px;
			min-width: 84px;
			height: 49px;
			margin-right: 0;
		}
	}

	&__slogan {

		@media only screen and (max-width: 1229px) {
			font-size: 17px;
		}

		@media only screen and (max-width: 979px) {
			font-size: 16px;
		}

		@media only screen and (max-width: 560px) {
			display: none;
		}

		max-width: 190px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 25px;
		border-left: 1px solid #d9d9d9;
		font-size: 18px;
		line-height: 20px;
		color: #757575;
	}

	&__contacts {
		margin-left: 20px;
		text-align: right;
	}

	&__tel {
		margin-bottom: 3px;
		font-size: 20px;
		font-weight: 700;

		@media only screen and (max-width: 560px) {
			font-size: 18px;
		}

		& a {
			color: inherit;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&__email {
		font-size: 18px;

		@media only screen and (max-width: 560px) {
			font-size: 16px;
		}
	}

	&__btn {
		margin-left: 20px;

		@media only screen and (max-width: 1140px) {
			display: none;
		}

		@media only screen and (max-width: 979px) {
			display: block;
			margin-top: 0;
			margin-left: 20px;
		}

		@media only screen and (max-width: 820px) {
			display: none;
		}

		&--copy {
			display: none;

			@media only screen and (max-width: 1140px) {
				display: block;
				margin-top: 8px;
				margin-left: 0;
			}

			@media only screen and (max-width: 979px) {
				display: none;
			}
		}
	}
}