.captcha {
	display: flex;

	max-width: 298px;
	margin: -4px -2px 0;
}

.captcha__input {
	width: 50%;
	margin-top: 4px;
	padding: 0 2px;
}

.captcha__pic {
	width: 50%;
	margin-top: 4px;
	padding: 0 2px;
	img {
		display: block;

		max-width: 100%;
		height: auto;

		border: 1px solid #dee4e8;
	}
}
