/* =======================
   Card
   ======================= */

.card {
	display: block;

	color: $black;
	&:hover {
		text-decoration: none;

		color: $blue;
	}
}

.card__hero {
	display: block;

	max-width: 100%;
	height: auto;
	margin: 0 auto 10px;
}

.card__caption {
	font-size: 17px;
	font-weight: bold;
	line-height: 1.25;

	text-align: center;
	.card:hover & {
		text-decoration: underline;
	}
}
