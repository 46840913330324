.goals {

	@include media($xl) {
		padding: 70px 30px 60px;
	}

	@include media($lg) {
		padding: 70px 30px 60px;
	}

	@include media($md) {
		padding: 40px 15px;
	}

	@include media($sm) {
		padding: 30px 15px 2px;
	}

	max-width: 1230px;
	margin: 0 auto;
}

.goals__title {
	@extend .about__title;
}

.goals__list {

	@include media($sm) {
		@include last-margin;

		display: block;
		margin: 0 -15px;
	}

	display: flex;
	flex-wrap: wrap;
	margin: 0 -1px 1px;
}

.goals__item {

	@include media($md) {
		width: calc(50% - 4px);
	}

	@include media($sm) {
		width: 100%;
		margin: 0 auto 2px;
	}

	display: block;
	width: calc(33.3333% - 6px);
	margin: 0 1px 2px;

	&:hover {
		text-decoration: none;
	}
}

.goals__pic {
	display: block;
	width: 100%;
	max-width: 100%;
	height: auto;
}

.goals__caption {

	@include media($xl) {
		font-size: 28px;
		line-height: 30px;
		min-height: 100px;
		padding: 20px;
	}

	@include media($lg) {
		font-size: 28px;
		line-height: 30px;
		min-height: 84px;
		padding: 12px 20px;
	}

	@include media($md) {
		font-size: 24px;
		line-height: 1;
		min-height: 64px;
		padding: 8px 20px;
	}

	@include media($sm) {
		font-size: 24px;
		line-height: 1;
		min-height: 64px;
		padding: 8px 20px;
	}

	font-weight: bold;
	position: relative;
	display: flex;
	text-align: center;
	color: white;
	background: $blue;
	align-items: center;
	justify-content: center;

	&:before {
		@include triangle(24px 12px, $blue, up);

		position: absolute;
		bottom: 100%;
		left: 50%;
		display: block;
		content: '';
		transform: translateX(-50%);
	}

	.goals__item:hover & {
		background: $blue;

		&:before {
			border-bottom-color: $blue;
		}
	}
}
