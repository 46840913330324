.advantages {
	color: white;
	background: $blue;
}

.advantages__inner {

	@include media($xl) {
		padding: 45px 30px 55px;
	}

	@include media($lg) {
		padding: 45px 30px 45px;
	}

	@include media($md) {
		padding: 40px 30px;
	}

	@include media($sm) {
		padding: 30px 15px;
	}

	max-width: 1230px;
	margin: 0 auto;
	text-align: center;
}

.advantages__title {
	@extend .about__title;

	color: white;
}

.advantages__list {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -30px;
}

.advantages__item {

	@include media($xl) {
		width: 16.6666%;
		padding: 0 15px;
	}

	@include media($lg) {
		width: 16.6666%;
		padding: 0 15px;
	}

	@include media($md) {
		width: 33.3333%;
		padding: 0 15px;
	}

	@include media($sm) {
		width: 50%;
	}

	margin-bottom: 30px;
	text-align: center;

	&:before {
		display: block;
		width: 46px;
		height: 46px;
		margin: 0 auto 18px;
		content: '';
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}

	&-health:before {
		background-image: url(../i/health.svg);
	}

	&-environment:before {
		background-image: url(../i/animals.svg);
	}

	&-euro:before {
		background-image: url(../i/control.svg);
	}

	&-quality:before {
		background-image: url(../i/quality.svg);
	}

	&-control:before {
		background-image: url(../i/euro.svg);
	}

	&-animals:before {
		width: 52px;
		background-image: url(../i/environment.svg);
	}
}

.advantages__caption {
	margin-bottom: 10px;

	@include media($sm) {
		line-height: 1.25;
	}

	font-size: 17px;
	font-weight: bold;

	&:before {
		display: block;
		width: 30px;
		height: 1px;
		margin: 0 auto 20px;
		content: '';
		background: $darkblue;
	}
}

.advantages__remark {
	font-size: 16px;

	@include media($xs) {
		font-size: 14px;
	}
}