@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,400italic,700,700italic&subset=latin,cyrillic);
@include font-face(iconfont, '../fonts/icomoon');

.icon {
    font-family: iconfont;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    display: inline-block;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    speak: none;
}

.icon-google-plus:before {
    content: '\e90f';
}

.icon-youtube:before {
    content: '\e90a';
}

.icon-odnoklassniki:before {
    content: '\e90b';
}

.icon-twitter:before {
    content: '\e90c';
}

.icon-facebook:before {
    content: '\e90d';
}

.icon-vk:before {
    content: '\e90e';
}

.icon-angle-down:before {
    content: '\e900';
}

.icon-angle-up:before {
    content: '\e909';
}

.icon-angle-tiny-right:before {
    content: '\e910';
}

.icon-angle-small-right:before {
    content: '\e903';
}

.icon-angle-left:before {
    content: '\e901';
}

.icon-angle-right:before {
    content: '\e902';
}

.icon-arrow-large-left:before {
    content: '\e904';
}

.icon-arrow-large-right:before {
    content: '\e905';
}

.icon-arrow-left:before {
    content: '\e906';
}

.icon-arrow-right:before {
    content: '\e907';
}

.icon-home:before {
    content: '\e908';
}

.icon-binardi:before {
    content: '\e914';
}

.icon-search:before {
    content: '\e912';
}
