.images {
	@include media($sm) {
		max-width: 362px;
	}

	position: relative;
	margin-bottom: 30px;
}

.images__hero {
	display: block;

	border: 1px solid #e9e9e9;
}

.images__heroPic {
	display: block;

	max-width: 100%;
	height: auto;
	margin-right: auto;
	margin-left: auto;
}

.images__thumbs {
	display: flex;

	margin: 0 -5px;

	flex-wrap: wrap;
}

.images__thumb {
	position: relative;

	width: calc(25% - 10px);
	margin: 10px 5px 0;

	cursor: pointer;

	border: 1px solid #e9e9e9;
	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		display: block;

		content: '';
		transition: opacity .2s ease;

		opacity: 0;
		background: $black;
	}
	&:after {
		font-family: iconfont;
		font-size: 24px;
		line-height: 1;

		position: absolute;
		top: 50%;
		left: 50%;

		display: block;

		width: 24px;
		height: 24px;
		margin: -12px 0 0 -12px;

		content: '\e912';
		transition: opacity .2s ease;

		opacity: 0;
		color: white;
	}
	&:hover {
		text-decoration: none;
		&:before {
			opacity: .4;
		}
		&:after {
			opacity: 1;
		}
	}
}

.images__thumbHero {
	display: block;

	width: 100%;
	height: auto;
}
