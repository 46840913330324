.sidebar {
	@include last-margin;
	@include media($xl) {
		float: left;

		width: 25%;
		padding-right: 30px;
	}
	@include media($lg) {
		float: left;

		width: 25%;
		padding-right: 30px;
	}
	@include media($md) {
		margin-bottom: 30px;
	}
	@include media($sm) {
		margin-bottom: 30px;
	}
	& > *:first-child {
		margin-top: 0;
	}
}

.sidebar__widgets {
	@include media($md) {
		display: flex;

		margin-right: -15px;
		margin-left: -15px;
	}

	margin-top: 30px;
	& > *:first-child {
		margin-top: 0;
	}
}

.sidebar__widget {
	margin-top: 30px;
	.sidebar__widgets & {
		@include media($md) {
			width: 50%;
			margin-top: 0;
			padding-right: 15px;
			padding-left: 15px;
		}
	}
}

.sidebar__caption {
	font-size: 18px;
	font-weight: bold;

	margin-bottom: .75em;

	color: $black;
}
