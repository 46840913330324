.process {
	margin-top: 30px;
	margin-bottom: 30px;

	& .process {  // yac

		&__list {
			margin: 0;
		}

		&__item {

			&::before {
				display: none;
			}
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		margin-bottom: -30px;
		padding: 0;
		list-style: none;
	}

	&__item {
		width: calc((99.9% - 20px * 3) / 4);
		margin-right: 20px;
		margin-bottom: 30px;

		&:nth-of-type(4n) {
			margin-right: 0;
		}

		@media only screen and (max-width: 720px) {
			width: calc((99.9% - 20px) / 2);

			&:nth-of-type(4n) {
				margin-right: 20px;
			}

			&:nth-of-type(2n) {
				margin-right: 0;
			}
		}

		@media only screen and (max-width: 410px) {
			width: 100%;
			margin-right: 0;

			&:nth-of-type(4n) {
				margin-right: 0;
			}
		}
	}

	&__number {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		min-width: 50px;
		height: 50px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
		border-radius: 50%;
		font-weight: bold;
		color: $blue;
		border: 2px solid $blue;
	}

	&__description {
		font-size: 16px;
		text-align: center;
	}
}